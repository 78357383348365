<script setup>
const emits = defineEmits(['openPopUpApplication']);

const openPopUpApplication = () => {
    emits('openPopUpApplication');
};
</script>

<template>
    <div class="main-description-medium">
        <h1 class="main-description-medium__title"><span
                class="main-description-medium__title-four">Кондиционирование</span><br>
            <span class="main-description-medium__title-five"> И Вентиляция </span><br>Чистый Воздух
            <span class="main-description-medium__title-second">С </span>
            <span class="main-description-medium__title-three">Air Arts</span>
        </h1>
        <div class="main-description-medium__line"></div>
        <div class="main-description-medium__line main-description-medium__line_second"></div>
        <img src="@/assets/img/Ellipse-3.svg" alt="Вспомогательное изображение" title="Иконка вспомогательного изображения"
            class="main-description-medium__circle" />
        <img src="@/assets/img/Ellipse-4.svg" alt="Вспомогательное изображение" title="Иконка вспомогательного изображения"
            class="main-description-medium__circle main-description-medium__circle_second" />
        <img src="@/assets/img/Ellipse-5.svg" alt="Вспомогательное изображение" title="Иконка вспомогательного изображения"
            class="main-description-medium__circle main-description-medium__circle_three" />
        <h2 class="main-description-medium__title-description">Проектирование, продажа, установка, ремонт и <br> <span
                class="main-description-medium__title-description-second"> обслуживание систем кондиционирования и
                вентиляции</span></h2>
        <div class="main-description-medium__wrapper-contacts">
            <img src="@/assets/img/icon-place.svg" alt="Адрес" title="Иконка адреса" class="main-description-medium__icon-place" />
            <div class="main-description-medium__wrapper-address">
                <p class="main-description-medium__address">г. Щёлково, ул. Беляева 7а,<br>Московская область</p>
            </div>
            <img src="@/assets/img/icon-phone.svg" alt="Телефон" title="Иконка телефона" class="main-description-medium__icon-phone" />
            <div class="main-description-medium__wrapper-phone">
                <p class="main-description-medium__phone"><a href="tel:+74951430202"
                        class="main-description-medium__phone-link">+7&nbsp;495&nbsp;143&nbsp;02&nbsp;02</a></p>
            </div>
        </div>
        <div class="main-description-medium__wrapper-contacts main-description-medium__wrapper-contacts_second">
            <NuxtImg src="/footer-block-mail.svg" alt="Email" title="Иконка электронной почты" class="main-description-medium__icon-email" />
            <div class="main-description-medium__wrapper-email">
                <p class="main-description-medium__phone"><a href="malito:info@air-arts.ru"
                        class="main-description-medium__phone-link">info@air-arts.ru</a></p>
            </div>
            <NuxtImg src="/icons-whatsapp.svg" alt="WhatsApp" title="Иконка вацапа" class="main-description-medium__icon-whatsapp" />
            <div class="main-description-medium__wrapper-whatsapp">
                <p class="main-description-medium__phone"><a href="https://wa.me/79773939575" target="_blank"
                        class="main-description-medium__phone-link">WhatsApp</a></p>
            </div>
        </div>
        <ButtonComponent class="main-description-medium__button" text="Оставить заявку" @click="openPopUpApplication" />
    </div>
</template>

<style lang="less">
.main-description-medium {
    width: 743px;
    position: relative;
    margin-top: 50px;

    &__title {
        font-family: 'Ruberoid';
        font-size: 55px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);

        &-second {
            font-family: 'Ruberoid';
            font-size: 55px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0em;
            position: relative;
            color: rgba(73, 94, 76, 1);
        }

        &-three {
            font-family: 'Ruberoid';
            font-size: 55px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0.03em;
            position: relative;
            color: rgba(120, 192, 129, 1);

        }

        &-four {
            font-family: 'Ruberoid';
            font-size: 55px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0em;
            position: relative;
            color: rgba(73, 94, 76, 1);
        }

        &-five {
            font-family: 'Ruberoid';
            font-size: 55px;
            font-weight: 600;
            line-height: 74px;
            letter-spacing: 0em;
            position: relative;
            color: rgba(73, 94, 76, 1);
            left: 335px;
        }

        &-description {
            font-family: 'Proxima Nova Rg';
            font-size: 18px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(124, 163, 129, 1);
            padding-left: 60px;

            &-second {
                padding-right: 97px;
            }
        }
    }

    &__line {
        width: 310px;
        height: 3px;
        position: absolute;
        top: 103px;
        left: 3px;
        background: rgba(120, 192, 129, 1);
        border-radius: 1.5px;

        &_second {
            top: 35px;
            left: 615px;
            width: 123px;
        }
    }

    &__circle {
        width: 10px;
        height: 10px;
        position: absolute;
        top: 232px;
        left: 0;

        &_second {
            left: 20px;
        }

        &_three {
            left: 40px;
        }
    }

    &__wrapper {
        &-contacts {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-right: 33px;
            margin-top: 30px;

            &_second {
                margin-top: 10px;
            }
        }

        &-email {
            width: 266px;
        }
    }

    &__icon {

        &-place,
        &-phone,
        &-whatsapp,
        &-email {
            margin-right: 20px;
        }

        &-whatsapp,
        &-email {
            width: 33px;
            height: 33px;
        }
    }

    &__wrapper-address {
        margin-right: 28px;
    }

    &__address,
    &__phone {
        font-family: 'Proxima Nova Rg';
        font-size: 19px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(123, 137, 125, 1);

        &-link {
            text-decoration: none;
            color: rgba(123, 137, 125, 1);
        }
    }

    &__button.button {
        width: 350px;
        height: 75px;
        margin-top: 46px;
        margin-left: 40px;
        border-radius: 20px;
        background-color: rgba(120, 192, 129, 0.25);

        .button__wrapper-content {
            width: 330px;
            height: 55px;
            border-radius: 15px;

            &:hover {
                background: rgba(84, 225, 101, 1);
                box-shadow: 2px -2px 12px 0px rgba(34, 111, 33, 0.36) inset;
            }

            .button__text {
                font-family: 'Ruberoid';
                font-size: 17px;
                font-weight: 600;
                line-height: 23px;
                letter-spacing: 0.04em;
                text-align: left;

            }
        }
    }
}
</style>
